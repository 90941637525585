import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
import SmoothScroll from "../elements/SmoothScroll";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class HeroFull extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div
            className={innerClasses}
            style={{
              background:
                "radial-gradient(ellipse at center, #ffffff 0, #ffffff00 90%)",
              borderRadius: 100,
            }}
          >
            <div className="hero-content">
              <h1
                className="mt-0 mb-16 reveal-from-top"
                data-reveal-delay="150"
              >
                <span>
                  <span style={{ color: "#009bfa" }}>Immerse</span> yourself in
                  your <span style={{ color: "#fb416b" }}>target</span>{" "}
                  language, one{" "}
                  <span style={{ color: "#7c7aff" }}>experience</span> at a time
                </span>
              </h1>
              <div className="container-xs">
                <p
                  className="m-0 mb-32 reveal-from-top"
                  data-reveal-delay="300"
                >
                  Activities and meetups for total immersion, hosted by native
                  speakers specifically for language learners
                </p>
                <div className="reveal-from-top" data-reveal-delay="450">
                  <SmoothScroll duration={1000} to="our-features">
                    <Button tag="a" color="primary" class="smooth-scroll">
                      Learn more
                    </Button>
                  </SmoothScroll>
                </div>
              </div>
            </div>
            {/* <div className="hero-figure reveal-from-bottom" data-reveal-delay="600">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/hero-image.png')}
                alt="Hero"
                width={896}
                height={504} />
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
