import React from "react";
import HeroFull from "components/sections/HeroFull";
import Input from "components/elements/Input";
import FormHint from "components/elements/FormHint";
import Select from "components/elements/Select";
import Button from "components/elements/Button";
import FeaturesSplit from "components/sections/FeaturesSplit";
import DualCta from "components/sections/DualCta";
import Cta from "components/sections/Cta";
import Modal from "components/elements/Modal";
import Accordion from "components/elements/Accordion";
import AccordionItem from "components/elements/AccordionItem";
import GenericSection from "components/sections/GenericSection";
import SectionHeader from "components/sections/partials/SectionHeader";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { Formik } from "formik";
import ScrollReveal from "utils/ScrollReveal";
import * as Yup from "yup";
import Layout from "components/Layout";
import { navigate } from "gatsby";

const SignupSchema = Yup.object().shape({
  // NAME: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  EMAIL: Yup.string().email("Invalid email").required("Required"),
  INTEREST: Yup.string().required("Required"),
  LOCATION: Yup.string().required("Required"),
  // SPOKEN: Yup.string().required("Required"),
  // LANGUAGE: Yup.string().required("Required"),
  // LEVEL: Yup.string().required("Required"),
});

class Home extends React.Component {
  state = {
    signUpModal: null,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ signUpModal: null });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ signUpModal: null });
  };

  componentDidMount() {
    if (typeof document !== `undefined`) {
      document.body.classList.add("is-loaded");
    }

    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  chooseActionWord(value) {
    switch (value) {
      case "Hosting experiences":
        return "host";
      case "Attending experiences":
        return "attend";
      case "Both!":
        return "host/attend";
      default:
        return "host/attend";
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Layout>
            <HeroFull className="illustration-section-01" />

            {/* <FeaturesTabs /> */}
            <DualCta
              onSignUp={(e, modal) => {
                e.preventDefault();
                this.setState({ signUpModal: modal });
              }}
            />
            {/* <Clients /> */}
            <FeaturesSplit />
            <GenericSection topDivider>
              <div className="container-xs">
                <SectionHeader
                  data={{ title: "Questions? We've got answers." }}
                  className="center-content"
                />
                <Accordion>
                  <AccordionItem
                    title="What can I expect at an experience?"
                    active
                  >
                    <p>
                      As language learners, the dream is to open up a new world
                      of experiences and people that only speak another
                      language. But this can take years of practice! Our
                      experiences aim to introduce you to that right away, with
                      helpful, patient, and clear spoken hosts. Whether you want
                      to go to a dinner party with a bunch of Spaniards, or
                      simply practice ordering sushi at a Japanese restaurant in
                      Tokyo, we got you.
                    </p>
                    <p>
                      Beginner experiences will be heavily context based. Think
                      a workout class or cooking class where gestures and
                      demonstration will cover most of it.
                    </p>
                    <p>
                      Before the experience you'll receive a vocabulary
                      worksheet and depending on the host, voice recordings of
                      what you might hear during the activity, so you can
                      prepare if you'd like.
                    </p>
                  </AccordionItem>
                  <AccordionItem
                    title="Is this just for while traveling?"
                    active
                  >
                    <p>
                      No! While of course these experiences will certainly help
                      you dive into another culture, it's a great way to get to
                      know people in your local area and see a different side of
                      where you live. Plus, then you're able to get a dose of
                      immersion whenever you want one!
                    </p>
                  </AccordionItem>
                  <AccordionItem title="How much do they cost?">
                    <p>
                      The price is set by individual hosts, but we recommend to
                      price similarly to what an activity of the same kind would
                      typically be, but adjust depending on their experience
                      level. Experienced language tutors that are hosting
                      experiences might charge more, whereas native speakers
                      that have a passion for sharing their language may charge
                      less.
                    </p>
                    <p>
                      <h6>Examples:</h6>
                      <ul>
                        <li>Dinner party from $20 to $60 </li>
                        <li>
                          Guided hikes and nature activities from $10 to $60
                        </li>
                        <li>Food tours from $20 to $50</li>
                        <li>Workout classes from $10 to $20</li>
                      </ul>
                    </p>
                  </AccordionItem>
                  <AccordionItem title="But COVID?!">
                    Until Covid subsides, all activities will be limited to
                    small groups (under 8) in only outdoor settings, and of
                    course only if local guidelines allow it.
                  </AccordionItem>
                  <AccordionItem title="What if I'm a total beginner?">
                    Most langauge experts recommend speaking from DAY ONE! And
                    these experiences are the kinds of things that beginnner
                    language learners could previously only have dreamed of
                    having access to. However... we get that it can be
                    intimidating! We recommmend doing a few weeks of skype
                    lessons with a site like{" "}
                    <a href="https://www.italki.com">italki</a> if you want to
                    ease in a bit more.
                  </AccordionItem>
                </Accordion>
              </div>
            </GenericSection>
            <Modal
              id="demo-modal"
              show={this.state.signUpModal}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join the waitlist!</h3>
                <p className="text-sm">
                  We're getting ready to launch in cities near you, sign up to
                  be notified and let us know where our language learners are
                  at!
                </p>
              </div>
              <Formik
                initialValues={{
                  NAME: "",
                  EMAIL: "",
                  INTEREST: "",
                  LOCATION: "",
                  SPOKEN: "",
                  LANGUAGE: "",
                  LEVEL: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values, actions) => {
                  const { EMAIL, ...rest } = values;
                  addToMailchimp(EMAIL, { ...rest }).then(({ result, msg }) => {
                    if (result !== "success") {
                      this.setState({
                        globalError: msg,
                      });
                      actions.setSubmitting(false);
                    } else {
                      navigate("/thank-you");
                    }
                  });
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form style={modalFormStyle} onSubmit={handleSubmit}>
                    <div className="mb-12">
                      <Input
                        name="NAME"
                        type="text"
                        label="This is a label"
                        placeholder="Your name"
                        labelHidden
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.NAME}
                        status={errors.NAME && touched.NAME && "error"}
                      />

                      <Select
                        name="INTEREST"
                        label="Pick a color"
                        placeholder="What are you interested in?"
                        labelHidden
                        onBlur={handleBlur}
                        value={values.INTEREST}
                        onChange={handleChange}
                        status={errors.INTEREST && touched.INTEREST && "error"}
                      >
                        <option>Hosting experiences</option>
                        <option>Attending experiences</option>
                        <option>Both!</option>
                      </Select>
                      <Input
                        name="EMAIL"
                        type="email"
                        label="This is a label"
                        placeholder="Your best email"
                        labelHidden
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.EMAIL}
                        status={errors.EMAIL && touched.EMAIL && "error"}
                      />
                      <Input
                        name="LOCATION"
                        type="text"
                        label="This is a label"
                        placeholder={`What cities can you ${this.chooseActionWord(
                          values.INTEREST
                        )} experiences?`}
                        labelHidden
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.LOCATION}
                        status={errors.LOCATION && touched.LOCATION && "error"}
                      />

                      {(values.INTEREST === "Hosting experiences" ||
                        values.INTEREST === "Both!") && (
                        <Input
                          name="SPOKEN"
                          type="text"
                          label="This is a label"
                          placeholder="What language(s) do you speak fluently?"
                          labelHidden
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.SPOKEN}
                          status={errors.SPOKEN && touched.SPOKEN && "error"}
                        />
                      )}
                      {(values.INTEREST === "Attending experiences" ||
                        values.INTEREST === "Both!") && (
                        <Input
                          name="LANGUAGE"
                          type="text"
                          label="This is a label"
                          placeholder="What language do you want to learn?"
                          labelHidden
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LANGUAGE}
                          status={
                            errors.LANGUAGE && touched.LANGUAGE && "error"
                          }
                        />
                      )}

                      {(values.INTEREST === "Attending experiences" ||
                        values.INTEREST === "Both!") && (
                        <Select
                          id="level"
                          name="LEVEL"
                          label="Pick a color"
                          placeholder="Current level in target language"
                          labelHidden
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.LEVEL}
                          status={errors.LEVEL && touched.LEVEL && "error"}
                        >
                          <option>Only know a few words</option>
                          <option>Can talk in simple phrases (A1)</option>
                          <option>Able to have basic conversations (A2)</option>
                          <option>
                            Comfortable talking about familiar subjects (B1)
                          </option>
                          <option>
                            Can understand most conversations (B2 + above)
                          </option>
                        </Select>
                      )}
                    </div>
                    <Button type="submit" color="primary" wide>
                      {isSubmitting ? "Submitting..." : "Keep me updated!"}
                    </Button>
                    {this.state.globalError && (
                      <FormHint>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.globalError,
                          }}
                        ></div>
                      </FormHint>
                    )}
                  </form>
                )}
              </Formik>
              {/* <div className="center-content mt-24">
            <a
              className="text-xxs fw-500 tt-u"
              aria-label="close"
              href="#0"
              onClick={this.closeModal}
            >
              No thanks!
            </a>
          </div> */}
            </Modal>

            {/* <Testimonial
          topDivider
          bottomOuterDivider
          className="gradient-section"
        /> */}
            <Cta
              hasBgColor
              invertColor
              split
              onSignUp={(e, modal) => {
                e.preventDefault();
                this.setState({ signUpModal: modal });
              }}
            />
          </Layout>
        )}
      />
    );
  }
}

export default Home;

const modalFormStyle = {
  maxWidth: "320px",
  margin: "0 auto",
};
