import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Immersion at the level that's right for you",
      paragraph:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
    };

    return (
      <section {...props} className={outerClasses} id="our-features">
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    Real world activities
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    Join a dinner party, workout class, arts & crafts hang, day
                    at a park or beach and many other types of activities with
                    native speakers that are ready to speak slowly, help you
                    understand and level up.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/activities.png")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    Practice locally or as an activity while traveling
                  </h3>
                  <p
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    Find an experience before you travel to practice immersion
                    at home. Or find an activity you’d pay for while traveling
                    anyway, but with a guide that knows you want to practice and
                    is ready to speak with you!
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/practice.png")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-bottom"
                    data-reveal-container=".split-item"
                  >
                    All language levels
                  </h3>
                  <div
                    className="m-0 reveal-from-bottom"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    <p>
                      We have experiences for all levels, though A2 and above is
                      recommended, and each activity comes with suggested
                      practice materials.
                    </p>
                    <p>
                      Languages available include Spanish, French, German,
                      Mandarin and Japanese!
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="has-shadow"
                    src={require("./../../assets/images/chart.png")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
