import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  render() {
    const { onSignUp } = this.props;

    return (
      <section
        className="dual-cta"
        // className={outerClasses}
      >
        <div className="container">
          <div className="cta left">
            <Image
              className="cta-image-left"
              src={require("./../../assets/images/girl.png")}
              alt="Earn money"
            />
            <div>
              <h4>Earn money by hosting experiences</h4>
              <Button onClick={(e) => onSignUp(e, "host")}>Sign up</Button>
            </div>
          </div>
          <div className="cta right">
            <div>
              <h4>Learn through fun and social activities</h4>
              <Button onClick={(e) => onSignUp(e, "learn")}>Sign up</Button>
            </div>
            <Image
              className="cta-image-right"
              src={require("./../../assets/images/picnic.png")}
              alt="Learn through activites"
            />
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
